<template>
    <div>
        <v-container grid-list-lg fluid>
            <v-layout row wrap>
                <v-flex lg12 md12 sm12 xs12>
                    <v-layout row wrap>
                        <v-flex lg4 md12 sm12 pr-6 pl-lg-6>
                            <v-layout row wrap justify-end>
                                <v-menu v-model="menuStart" :close-on-content-click="false" transition="scale-transition"
                                    offset-y max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="computedStartDateFormatted" label="Từ ngày" hint="DD-MM-YYYY"
                                            persistent-hint prepend-icon="fa-calendar-alt" readonly
                                            v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateRange.startDate" no-title
                                        @input="menuStart = false"></v-date-picker>
                                </v-menu>
                            </v-layout>
                        </v-flex>
                        <v-flex lg4 md12 sm12 pr-6>
                            <v-layout row wrap justify-end>
                                <v-menu v-model="menuEnd" :close-on-content-click="false" transition="scale-transition"
                                    offset-y max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="computedEndDateFormatted" label="Đến ngày" hint="DD-MM-YYYY"
                                            persistent-hint prepend-icon="fa-calendar-alt" readonly
                                            v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateRange.endDate" no-title
                                        @input="menuEnd = false"></v-date-picker>
                                </v-menu>
                            </v-layout>
                        </v-flex>
                        <v-flex lg2 md12 sm12 pr-6 pt-0>
                            <v-select :items="reportTypes" item-value="value" item-text="name" v-model="selectReportType" label="Hiển thị theo" @change="filter_data"></v-select>
                        </v-flex>
                        <v-flex lg2 md12 sm12 pr-6>
                            <v-btn @click="filter_data" color="#a41c21" outlined>Xem</v-btn>
                        </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                        <v-flex lg3 md12 sm12 pl-6 pr-6>
                            <v-select clearable :items="staffs" item-value="ID" item-text="FullName"
                                v-model="selectedCSId" label="CSKH"></v-select>
                        </v-flex>
                        <v-flex lg3 md12 sm12 pr-6>
                            <v-select clearable :items="staffs" item-value="ID" item-text="FullName"
                                v-model="selectedSaleId" label="Sale"></v-select>
                        </v-flex>
                        <v-flex lg3 md12 sm12 pr-6>
                            <v-select :items="packageStatus" item-value="value" item-text="name" v-model="selectedStatus"
                                label="Trạng thái"></v-select>
                        </v-flex>
                        <v-flex lg3 md12 sm12 pr-6>
                            <v-select :items="product_types" item-value="value" item-text="name" v-model="selectedType"
                                label="Loại hàng"></v-select>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex lg6 md6 sm12 xs12 v-if="selectReportType == 1">
                    <v-layout row wrap>
                        <v-flex lg12 md12 sm12 xs12>
                            <highcharts :options="chartWeight"></highcharts>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex lg6 md6 sm12 xs12 v-if="selectReportType == 1">
                    <v-layout row wrap>
                        <v-flex lg12 md12 sm12 xs12>
                            <highcharts :options="chartAmount"></highcharts>
                        </v-flex>
                    </v-layout>
                </v-flex>

                <v-flex lg12>
                    <v-card class="mb-8">
                        <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
                        <v-data-table :headers="headers" hide-default-footer :sortable="false"
                            :items="items" :server-items-length="items ? items.length : 0" :loading="loading"
                            :mobile-breakpoint="100" :options.sync="pagination">
                            <template v-slot:body.prepend>
                                <tr class="table-summary">
                                    <td class="font-weight-bold" :colspan="selectReportType == 1 ? 2 : 1">TỔNG</td>
                                    <td class="font-weight-bold text-end">{{ summary.TotalWeight | currency('', 3) }}</td>
                                    <td class="font-weight-bold text-end">{{ summary.TotalVolume | currency('', 3) }}</td>
                                    <td class="font-weight-bold text-end">{{ summary.TotalFeeShipVND | currency }}</td>
                                    <td class="font-weight-bold text-end">{{ summary.TotalAmount | currency }}</td>
                                    <td class="font-weight-bold text-end">{{ summary.TotalRevenue | currency }}</td>
                                </tr>
                            </template>
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td v-if="selectReportType == 1">{{ item.ReportDate.toString().replace(/(\d{4})(\d{2})(\d{2})/, "$3-$2-$1") }}</td>
                                    <td>{{ item.Username }}</td>
                                    <td class="text-end">{{ item.TotalWeight | currency('', 2) }}</td>
                                    <td class="text-end">{{ item.TotalVolume | currency('', 3) }}</td>
                                    <td class="text-end">{{ item.TotalFeeShipVND | currency }}</td>
                                    <td class="text-end">{{ item.TotalAmount | currency }}</td>
                                    <td class="text-end">{{ item.TotalRevenue | currency }}</td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
import { Chart } from 'highcharts-vue';
import { mapState } from "vuex";
import configs from "@/configs";
import _ from "lodash";
import Filtering from "@/components/Filtering";
import moment from "moment"
export default {
    components: {
        highcharts: Chart,
        "hpo-filter": Filtering,
    },
    data() {
        return {
            viewName: "bao-cao-loi-nhuan-khach-hang",
            dateRange: {
                startDate: moment.utc(Date.now()).startOf('month').toISOString().substr(0, 10),
                endDate: moment.utc(Date.now()).endOf('month').toISOString().substr(0, 10),
            },
            filterConditions: [],
            quickFilterConditions: [],
            menuStart: false,
            menuEnd: false,
            packageStatus: [
                { value: -1, name: 'Tất cả'},
                { value: 999, name: 'Theo phiếu xuất'},
                ...configs.PACKAGE_STATUS
            ],
            selectedStatus: 5,
            selectedType: -1,
            selectedBuyerId: 0,
            selectedCSId: 0,
            selectedSaleId: 0,
            pagination: {  ...configs.PAGINATION,  sortBy: ["ReportDate"]  },
            reportTypes: [
                {'value': 1, 'name': 'Theo ngày'},
                {'value': 2, 'name': 'Theo khách'}
            ],
            selectReportType: 1
        };
    },
    watch: {
        pagination: {
            handler: function (val, oldVal) {
                this.filter_data();
            },
            deep: true
        },
    },
    computed: {
        ...mapState({
            items: state => state.report.customerReport.data,
            loading: state => state.report.loading,
            staffs: state => state.commons.all_users,
            cs_staffs: state =>
            _.filter(state.commons.all_users, function(o) {
                return (o.RoleType & configs.ROLE_TYPE.Purchase) == configs.ROLE_TYPE.Purchase;
            }),
            configuration: state => state.configuration.selected
        }),
        product_types() {
            return this.configuration.ProductTypes ? [{ value: -1, name: 'Tất cả'}, ...JSON.parse(this.configuration.ProductTypes)] : [{ value: -1, name: 'Tất cả'}];
        },
        computedStartDateFormatted() {
            return moment(this.dateRange.startDate).format(configs.SHORT_DATE_FORMAT);
        },
        computedEndDateFormatted() {
            return moment(this.dateRange.endDate).format(configs.SHORT_DATE_FORMAT);
        },
        adminRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
        },
        headers() {
            let tmp = [
                {
                    text: "Khách hàng",
                    value: "Username",
                    sortable: true,
                    filterable: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Tổng cân",
                    value: "TotalWeight",
                    sortable: true,
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Tổng khối",
                    value: "TotalVolume",
                    sortable: true,
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Phí khác",
                    value: "TotalFeeShipVND",
                    sortable: true,
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Doanh thu",
                    value: "TotalAmount",
                    sortable: true,
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Lợi nhuận",
                    value: "TotalRevenue",
                    sortable: true,
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
            ];
            if(this.selectReportType == 1) {
                tmp.unshift({
                    text: "Ngày",
                    value: "ReportDate",
                    sortable: true,
                    filterable: false,
                    dataType: configs.DATA_TYPE["Number"]
                })
            }
            return tmp;
        },
        summary() {
            var ret = {
                'TotalWeight': _.sumBy(this.items, 'TotalWeight'),
                'TotalVolume': _.sumBy(this.items, 'TotalVolume'),
                'TotalFeeShipVND': _.sumBy(this.items, 'TotalFeeShipVND'),
                'TotalAmount': _.sumBy(this.items, 'TotalAmount'),
                'TotalCost': _.sumBy(this.items, 'TotalCost'),
                'TotalRevenue': _.sumBy(this.items, 'TotalRevenue')
            };
            return ret;
        },
        chartWeight() {
            const dataChart = _(this.items).groupBy('ReportDate').map((p, id) => ({
                            ReportDate: id,
                            TotalWeight: _.sumBy(p, 'TotalWeight'),
                            TotalVolume: _.sumBy(p, 'TotalVolume')
                        })).value()
            var ret = {
                chart: { type: 'line' },
                title: { text: 'Doanh thu kg/m3' },
                credits: { enabled: false },
                tooltip: { shared: true },
                xAxis: {
                    categories: _.map(dataChart, function (x) { return x.ReportDate.toString().replace(/(\d{4})(\d{2})(\d{2})/, "$3-$2-$1") })
                },
                yAxis: {
                    title: {
                        text: 'Tổng kg/m3'
                    }
                },
                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                series: [
                    {
                        name: 'KG',
                        data: _.map(dataChart, 'TotalWeight'),
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return parseFloat(this.y.toFixed(2)).toLocaleString();
                            }
                        },
                    },
                    {
                        name: 'M3',
                        data: _.map(dataChart, 'TotalVolume'),
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return parseFloat(this.y.toFixed(3)).toLocaleString();
                            }
                        },
                    }
                ]
            };

            return ret;
        },
        chartAmount() {
            const dataChart = _(this.items).groupBy('ReportDate').map((p, id) => ({
                            ReportDate: id,
                            TotalFeeShipVND: _.sumBy(p, 'TotalFeeShipVND'),
                            TotalAmount: _.sumBy(p, 'TotalAmount'),
                            TotalCost: _.sumBy(p, 'TotalCost'),
                            TotalRevenue: _.sumBy(p, 'TotalRevenue'),
                        })).value()
            var ret = {
                chart: { type: 'line' },
                title: { text: 'Tổng doanh thu' },
                credits: { enabled: false },
                tooltip: { shared: true },
                xAxis: {
                    categories: _.map(dataChart, function (x) { return x.ReportDate.toString().replace(/(\d{4})(\d{2})(\d{2})/, "$3-$2-$1") })
                },
                yAxis: {
                    title: {
                        text: 'Tổng tiền (VNĐ)'
                    }
                },
                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                series: [
                    {
                        name: 'Phí khác',
                        data: _.map(dataChart, 'TotalFeeShipVND')
                    },
                    {
                        name: 'Doanh thu',
                        data: _.map(dataChart, 'TotalAmount')
                    },
                    {
                        name: 'Lợi nhuận',
                        data: _.map(dataChart, 'TotalRevenue')
                    }
                ]
            };

            return ret;
        }
    },
    methods: {
        filter_data: function () {
            this.$store.dispatch("commons/getAllUsers");
            var param = {
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions,
                startTime: moment(this.dateRange.startDate).format(configs.SQL_DATE_FORMAT),
                endTime: moment(this.dateRange.endDate).format(configs.SQL_DATE_FORMAT),
                csid: this.selectedCSId | 0,
                saleid: this.selectedSaleId | 0,
                status: this.selectedStatus,
                product_type: this.selectedType,
                report_type: this.selectReportType,
                pagination: this.pagination
            };
            this.$store.dispatch("report/getCustomerReport", param);
        },
        applyFilter(filterConditions) {
            this.filterConditions = filterConditions;
            this.filter_data();
        },
        quickFilter(filterConditions) {
            this.quickFilterConditions = filterConditions;
            this.filter_data();
        },
    },
    mounted() {
        this.filter_data();
        this.$store.dispatch("configuration/getDetail", 1);
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
    }
};
</script>
<style scoped>
.rate-p {
    margin-bottom: 0px;
    margin-top: 16px;
}

.rate-p2 {
    margin-bottom: 16px;
    margin-top: 8px;
    color: rgb(168, 168, 168);
}
</style>